import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { Transaction } from './models/transaction.model';
import { InstantNotificationService } from './services/instant-notification.service';
import { SessionService } from './shared/auth/session.service';
import {StatDateInterval} from './Utils/DateInterval'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  _newTransactionSub: Subscription;
  constructor(
    private instantPaymentNotification: InstantNotificationService,
    private sessionService: SessionService,
    private router: Router,
    private statDateInterval: StatDateInterval,
    ) {}
  ngOnInit() {
    this.instantPaymentNotification.subscribeToTransactionNotification(this.sessionService.getUser().id);
    this._newTransactionSub = this.instantPaymentNotification.transactionsStatusChanged.subscribe(({transaction, error} )=> {
      if (transaction.status === 'success') {
        this.showSuccesPaymentSwal(transaction)
      } else if (transaction.status === 'failed') {
        this.showErrorSwal(transaction, error.errorCode);
      }
      console.log({transaction})
    });
    this.onResize(null);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {

    const innerWidth = window.innerWidth;
    if(innerWidth > 767) {
      document.body.setAttribute('data-sidebar-style', 'full');
    }

    if(innerWidth < 768) {
      document.body.setAttribute('data-sidebar-style', 'overlay');
    }
  }

  showErrorSwal(transaction: Transaction, errorMessage) {
    Swal.fire({
      title: 'Echec de la transaction',
      icon: 'error',
      html:
      `
      <div style="margin-left: 20px; text-align: left">
        <b>Cause de l'echec</b> : ${errorMessage}<br/>
        <b>Nom client </b>: ${ transaction.firstName } ${ transaction.lastName }</br>
        <b>Numéro de téléphone</b>: ${ transaction.phoneNumber }<br/>
        <b>Moyen de paiement</b>: <img src="${ transaction.service.serviceLogoName }" width="25" /><br/>
      </div>
      `,
    }).then((result) => {
      if(result.value) {
        location.reload();
      }
    });
  }

  showSuccesPaymentSwal(transaction) {
    Swal.fire({
      icon: 'success',
      title: 'Transaction validée',
      html:
      `
      <div style="margin-left: 20px; text-align: left">
        <b>Référence</b>: ${ transaction.id }</br>
        <b>Nom client</b>: ${ transaction.firstName } ${ transaction.lastName }</br>
        <b>Numéro de téléphone</b>: ${ transaction.phoneNumber }<br/>
        <b>Moyen de paiement</b>: <img src="${ transaction.service.serviceLogoName }" width="25" /><br/>
        <b>Date </b>: ${ this.statDateInterval.formatDate(transaction.date)} à ${this.statDateInterval.formatTime(transaction.date) }
      </div>
      `,
      imageWidth: 60,
      imageHeight: 40,
      showCancelButton: true,
      cancelButtonText: 'Fermer',
      confirmButtonColor: '#423c6c',
      confirmButtonText: '<i class="fa fa-info-circle" ></i> Voir détail',
    }).then((result: SweetAlertResult) => {
      if(result.value) {
        this.router.navigate([`/transactions/details/${transaction.koparId}`], {
          state: {
            koparId: transaction.koparId
          }
        });
      }
    })
  }

  ngOnDestroy() {
    this._newTransactionSub.unsubscribe()
  }
}
