<!--**********************************
    Sidebar start
***********************************-->
<div class="deznav">
  <div class="deznav-scroll nav-scroll">
    <ul class="metismenu" id="menu">
      <li>
        <a
          class="has-arrow ai-icon"
          href="javascript:void()"
          aria-expanded="false"
        >
          <i class="flaticon-381-networking"></i>
          <span class="nav-text">Dashboard</span>
        </a>
        <ul aria-expanded="false">
          <li><a  [routerLink]="['/ma-caisse']">Ma caisse</a></li>
          <li><a *ngIf="hasRole(menuRoles.MListMembers)" [routerLink]="['/caisses']">Mon équipe</a></li>
          <li><a *ngIf="hasRole(menuRoles.MTransactions)" [routerLink]="['/transactions']">Transactions</a></li>
          <li><a *ngIf="hasRole(menuRoles.MTransactions)" [routerLink]="['/invoices']">Factures envoyées</a></li>
          <li><a *ngIf="hasRole(menuRoles.MDecaissement)" [routerLink]="['/decaissements']">Décaissements</a></li>
        </ul>
      </li>
      <li>
        <a class="has-arrow ai-icon" aria-expanded="false">
          <i class="flaticon-381-box"></i>
          <span class="nav-text">Gestion de stock</span>
        </a>
        <ul aria-expanded="false">
          <li><a *ngIf="hasRole(menuRoles.MProducts)" [routerLink]="['/produits']">Mes produits</a></li>
        </ul>
      </li>
      <li>
        <a class="has-arrow ai-icon" aria-expanded="false">
          <i class="flaticon-381-box"></i>
          <span class="nav-text">Payment de masse</span>
        </a>
        <ul aria-expanded="false">
          <li><a *ngIf="hasRole(menuRoles.MNewBulkPayment)" [routerLink]="['/bulk-payment']">Nouveau paiement</a></li>
          <li><a *ngIf="hasRole(menuRoles.MBulkPayment)" [routerLink]="['/bulk-payment/list']">Liste paiement</a></li>
        </ul>
      </li>
    </ul>
  </div>
</div>
<style>
  .nav-scroll {
    overflow-y: scroll;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .nav-scroll::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .nav-scroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
</style>
<!--**********************************
    Sidebar end
***********************************-->
