import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
//COMPONENTS
import { AppFooterComponent } from "./app-footer/app-footer.component";
import { AppHeaderComponent } from "./app-header/app-header.component";
import { AppSidebarComponent } from "./app-sidebar/app-sidebar.component";

@NgModule({
  exports: [
    CommonModule,
    NgbModule,
    TranslateModule,
    AppFooterComponent,
    AppHeaderComponent,
    AppSidebarComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    TranslateModule,
    PerfectScrollbarModule
  ],
  declarations: [
    AppHeaderComponent,
    AppSidebarComponent,
    AppFooterComponent
  ],
})
export class SharedModule { }
