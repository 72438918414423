import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class MerchantsService {
  token: string;
  koparPayUrl = environment.koparPayUrl;
  private myMerchant = null;
  constructor(private http: HttpClient) {}
  getMerchants() {
    return this.http.get(`${this.koparPayUrl}/admin/merchants`)
    .pipe(map((o: any) => o.data));
  }
  getMerchantInfos(merchantKoparId) {
    return this.http.get(`${this.koparPayUrl}/merchants/full/${merchantKoparId}`)
    .pipe(map((o: any) => o.data));
  }
  
  getBulk(bulkId) {
    return this.http.get(`${this.koparPayUrl}/merchant/transactions/${bulkId}`)
    .pipe(map((o: any) => o.data));
  }
  confirmBulk(bulkId, password) {
    return this.http.post(`${this.koparPayUrl}/transaction/merchant/bulk_request_payment/${bulkId}/confirm`, {
      pass: password,
      bulkId
    })
    .pipe(map((o: any) => o.data));
  }
  getMyMerchantInfos(){
    return this.http.get(`${this.koparPayUrl}/merchants/myMerchant`)
    .pipe(map((o: any) => {
      if (o && o.data){
        this.myMerchant = o.data.merchant;
      }
      return o.data;
    }));
  }
  get getMyMerchant(){
    return this.myMerchant;
  }
  updateLocalMerchant(newInfos){
    this.myMerchant = newInfos;
  }
}
