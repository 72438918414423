export const environment = {
  production: true,
  apiUrl: 'https://koparpay.com/api',
  koparPayUrl: 'https://koparpay.com/api',
  webSocketUrl: 'ws://koparpay.com',
  apiTickets: 'https://koparexpress.com/crm_api',

  // apiUrl: 'http://localhost:9000/api',
  // koparPayUrl: 'http://localhost:9000/api',
  // webSocketUrl: 'ws://localhost:9000',
  // apiTickets: 'http://localhost:9300/crm_api',
  
  // koparPayUrl: 'https://kdevapi221.koparpay.com/api',
  // koparPayUrl: 'https://koparpay.com/api',
  // collectsUrl: 'https://kdevapi221.koparexpress.com/apps/collectes',
  collectsUrl: 'https://koparexpress.com/apps/collectes'
};
