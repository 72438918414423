import { Routes } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'ma-caisse',
    loadChildren: () => import('../../my-wallet/my-wallet.module').then(m => m.MyWalletModule)
  },
  {
    path: 'caisses',
    loadChildren: () => import('../../wallets/wallets.module').then(m => m.WalletsModule)
  },
  {
    path: 'transactions',
    loadChildren: () => import('../../transactions/transactions.module').then(m => m.TransactionsModule)
  },
  {
    path: 'invoices',
    loadChildren: () => import('../../factures-envoyees/factures-envoyees.module').then(m => m.FacturesEnvoyeesModule)
  },
  {
    path: 'decaissements',
    loadChildren: () => import('../../decaissement/decaissement.module').then(m => m.DecaissementModule)
  },
  {
    path: 'produits',
    loadChildren: () => import('../../products/products.module').then(m => m.ProductsModule)
  },
  {
    path: 'bulk-payment',
    loadChildren: () => import('../../bulk-payment/bulk-payment.module').then(m => m.BulkPaymentModule)
  }
];
