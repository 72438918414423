import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InstantNotificationService } from 'src/app/services/instant-notification.service';
import { MerchantsService } from 'src/app/services/merchant.service';
import { SessionService } from '../auth/session.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css']
})
export class AppHeaderComponent implements OnInit {
  currentUser: any;
  merchantUser: any;
  invoices = []

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private instantPaymentNotification: InstantNotificationService,
    private merchantService:MerchantsService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.sessionService.getUser();
    this.merchantUser = this.sessionService.getMerchant();
    this.instantPaymentNotification.invoicesStatusChanged.subscribe(({transaction, error}) => {
      this.invoices.push(transaction);
    });
    this.loadBalance();
    console.log('current merchant : ', this.merchantUser);
  }
  get myMerchant(){
    return this.merchantService.getMyMerchant;
  }
  loadBalance(){
    this.merchantService.getMyMerchantInfos().subscribe();
  }
  toggleHideSidebar() {
    const mainWrapper = document.getElementById("main-wrapper");
    const hamburger = document.querySelector(".hamburger");
    mainWrapper.classList.toggle("menu-toggle");
    hamburger.classList.toggle("is-active");
  }
  logout() {
    this.sessionService.logout();
    this.router.navigate(['pages/login'])
  }

  getRoleLabel(roleName) {
    const labels = {
      merchant_admin: 'Admin Marchand'
    }

    return labels[roleName] || roleName;
  }
}
