<!--**********************************
    Footer start
***********************************-->
<div class="footer">
	<div class="copyright">
		<p>Copyright  &copy;  {{currentDate | date: 'yyyy'}} <a id="link" href="https://www.koparexpress.com">Kopar Express</a>, Tous droits réservés, <a id="link-cdu" target="_blank" href="https://www.koparexpress.com/cdu.html">CDU</a>, <a id="link-cga" target="_blank" href="https://www.koparexpress.com/cga.html">CGA</a>.</p>
	</div>
</div>
<!--**********************************
    Footer end
***********************************-->
