import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Socket } from 'ngx-socket-io';
import { Transaction } from '../models/transaction.model';
@Injectable({providedIn: 'root'})
export class InstantNotificationService {

  static TRANSACTION_NOTIFICATION_SUBSCRIBE = 'MERCHANT_TRANSACTION_NOTIFICATION_SUBSCRIBE';
  static TRANSACTION_STATUS_CHANGED = 'MERCHANT_TRANSACTION_STATUS_CHANGED';
  static INVOICE_STATUS_CHANGED = 'MERCHANT_INVOICE_STATUS_CHANGED';
  transactionsStatusChanged = this.socket
                                .fromEvent<{transaction: Transaction, error: any}>
                                 (InstantNotificationService.TRANSACTION_STATUS_CHANGED)
  invoicesStatusChanged = this.socket
                                .fromEvent<{transaction: Transaction, error: any}>
                                 (InstantNotificationService.INVOICE_STATUS_CHANGED)

  constructor(private socket: Socket) {}

  subscribeToTransactionNotification(userId) {
    this.socket.emit(InstantNotificationService.TRANSACTION_NOTIFICATION_SUBSCRIBE, userId);
  }
}
