export const MenuRoles = {
	MWalletAdmin: ['admin', 'merchant_admin', 'merchant_supervisor', 'merchant_accountant', 'merchant_caissier'],
	MListMembers: ['admin', 'merchant_admin', 'merchant_supervisor', 'merchant_accountant'],
	MTransactions: ['admin', 'merchant_admin', 'merchant_supervisor', 'merchant_accountant', 'merchant_caissier'],
	MDecaissement: ['admin', 'merchant_admin', 'merchant_supervisor', 'merchant_accountant'],
  MProducts: ['admin', 'merchant_admin', 'merchant_supervisor', 'merchant_accountant', 'merchant_caissier'],
  MBulkPayment: ['admin', 'merchant_admin', 'merchant_supervisor', 'merchant_accountant'],
  MNewBulkPayment: ['admin', 'merchant_admin', 'merchant_accountant'],
  MNewPayment: ['admin', 'merchant_admin', 'merchant_accountant', 'merchant_caissier'],
  MAddMember: ['admin', 'merchant_admin'],
  MNewDecaissement: ['admin', 'merchant_admin', 'merchant_accountant'],
  MAddProduct: ['admin', 'merchant_admin'],
}
