import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FullLayoutComponent } from './full-layout/full-layout.component';
import { ContentLayoutComponent } from './content-layout/content-layout.component';

import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";

const routes: Routes = [
  {
    path: '',
    redirectTo: '/ma-caisse',
    pathMatch: 'full',
  },
  { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES, },
  { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES,  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes,{
    useHash: true
 })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
