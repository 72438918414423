import { Component, OnInit } from '@angular/core';
import { SessionService } from '../auth/session.service';
import { MenuRoles } from './route.roles';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app-sidebar.component.html',
  styleUrls: ['./app-sidebar.component.css']
})
export class AppSidebarComponent implements OnInit {
  menuRoles = MenuRoles;
  constructor(private sessionService: SessionService,) { }

  ngOnInit(): void {
  }
  hasRole(roles) {
    return this.sessionService.hasRoles(roles)
  }
}
