import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({providedIn: 'root'})
export class StatDateInterval{
    startDate = null;
    endDate = null;
    constructor(){
			moment.locale('fr');
			let d = new Date();
			console.log(d, d.getDate());
			let mois: any =  d.getMonth() + 1 ;
			let annee = d.getFullYear();
			let jours:any = d.getDate();
			if (jours<10){ jours = '0'+jours; }
			if (mois<10) { mois = '0'+mois }
			this.startDate =  annee + '-' + mois + '-'+ jours;
			this.endDate = annee + '-' +  mois + '-' + jours ;
    }
    getIntervalQueryString() {
			return (this.startDate && this.endDate) ? `?startDate=${this.startDate}&endDate=${this.endDate}`:'';
    }
    getIntervalParams() {
			return (this.startDate && this.endDate) ? `${this.startDate}T00:00:00.000Z/${this.endDate}T23:59:59.999Z`:'';
    }
    setStartDate(newStartDate){
			this.startDate = newStartDate;
    }
    setEndDate(newEndDate){
			this.endDate = newEndDate;
    }
    getStartDate(){
			return this.startDate;
    }
    getEndDate(){
			return this.endDate;
    }
    resetNewDate() {
			this.startDate = new Date().toISOString().substr(0, 10);
			this.endDate = new Date().toISOString().substr(0, 10);
    }
    formatDate(date) {
        return moment(date).format('DD MMM YYYY');
    }
    formatTime(date) {
        return moment(date).format('HH:mm');
    }

}